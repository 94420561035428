<template>
  <div>
    <div>
      <van-field @click="signS(items)" :disabled="items.editdis"
                 placeholder="点击签名" :rules="items.required?[{required:true,message:'必填'}]:[{}]">
        <template #extra>
          <van-image width="100" height="40" v-if="resultImg != ''" :src="resultImg"></van-image>
        </template>
      </van-field>
    </div>
    <van-popup v-model="signshow" position="bottom" :style="{height: '100%'}" closeable>
      <div>
        <div class="mcenter padd20 font15">签名</div>
        <div class="line_bottom"></div>
        <div>
          <vue-esign ref="esign" :width="800" :height="500" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor"
                     :bgColor.sync="bgColor" style="margin: auto;"/>
        </div>
        <div class="line_bottom"></div>
        <div class="mcenter mt20 mr20 mb10 font13">
<!--          <van-button size="small" @click="resetCav"><span class="paddzy20">清 空</span></van-button>
          <van-button type="info" size="small" @click="handleGenerate" class="ml15"><span class="paddzy20">确 认</span></van-button>-->
          <van-row>
            <van-col span="12"><div class="border padd10 colchose"><span @click="resetCav" class="paddzy20">清 空</span></div></van-col>
            <van-col span="12"><div class="border padd10 bg18f colw "><span @click="handleGenerate" class="paddzy20">确 认</span></div></van-col>
          </van-row>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import vueEsign from "vue-esign"
import mixins from "@/mixins"
import {Toast} from "vant";
export default {
  name: "signElement",
  mixins: [mixins],
  components: {
    vueEsign,
  },
  props:["items",'ruleForm','name'],
  data(){
    return{
      lineWidth: 4,
      lineColor: '#000000',
      bgColor: '#fff',
      resultImg: '',
      isCrop: false,
      signshow: false,//显示签名面板
      //signData: "",
    }
  },
  mounted() {
  },
  methods: {
    resetCav() {
      //console.log("res")
      this.$refs['esign'].reset() //清空画布
    },
    handleGenerate() {
      //console.log("makesure")
      this.$refs['esign'].generate().then(res => {
        this.resultImg = res // 得到了签字生成的base64图片
        this.ruleForm[this.items.name] = res.replace("data:image/png;base64,","");
        //console.log("item",this.resultImg)
        this.signshow = false;
      }).catch(err => { //  没有签名，点击生成图片时调用
        Toast({
          message: err + ' 未签名！',
        })
        //alert(err) // 画布没有签字时会执行这里 'Not Signned'
      })
    },
    signS(item){
      this.signshow = true;
      if(!this.isEmptyData(this.ruleForm)&&!this.isEmptyData(item)) {
        this.resultImg = "data:image/png;base64,"+this.ruleForm[item.name];
      }
    },
    /*submitSign(res){
      this.signData=res;
      this.ruleForm[this.currentItem.name]=res.replace("data:image/png;base64,","");
      this.signshow = false;
      console.log("sign",this.ruleForm[this.currentItem.name])
    }*/
  }
}
</script>

<style scoped>
.border{border: 1px solid #1890ff;border-radius: 8px;}
.bg18f{background: #1890ff;}
</style>