<template>
  <div>
    <van-nav-bar title="模板" :border="false">
      <template #left>
        <van-icon name="arrow-left" color="#000000" size="18" @click="onClickLeft"/>
      </template>
      <template #right>
        <span @click="save" class="col19">完成</span>
      </template>
    </van-nav-bar>
    <div>
      <form-display :group="temp.fields" :ruleForm="ruleForm"></form-display>
    </div>
  </div>
</template>

<script>
import formDisplay from "@/components/formDisplay";
export default {
  name: "batchDeal",
  props: ['temp','ruleForm'],
  components: {
    formDisplay,
  },
  data(){
    return{}
  },
  methods: {
    save(){},
    onClickLeft(){
      this.$emit('changedis',0)
    },
  }
}
</script>

<style scoped>

</style>