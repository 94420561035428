import { render, staticRenderFns } from "./calcElement.vue?vue&type=template&id=2938d048&scoped=true"
import script from "./calcElement.vue?vue&type=script&lang=js"
export * from "./calcElement.vue?vue&type=script&lang=js"
import style0 from "./calcElement.vue?vue&type=style&index=0&id=2938d048&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2938d048",
  null
  
)

export default component.exports