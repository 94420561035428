<template>
  <div>
    <div v-if="dis == 99">
      <success></success>
    </div>
    <div v-else-if="dis == 3" class="mcenter mt45vh">
      <div class="border p10">
        <open-app :ids="ids"></open-app>
      </div>
    </div>
    <div v-else-if="dis == 1">
      <add-list :group="formlist" :ruleForm="grouplist" :batch="batchs" @changedis="changedis"></add-list>
    </div>
    <div v-else-if="dis == 2">
      <batch-deal :temp="currenttemp" :ruleForm="grouplist" @changedis="changedis"></batch-deal>
    </div>
    <div v-else>
      <div v-if="datalist.title!=null">
        <van-row>
          <van-col span="20" class="mt5vh mright mr10">
            <span class="txt">{{userinfo.nickname}}</span>
          </van-col>
          <van-col span="2" class="mt4vh mr10">
            <van-image width="2rem" height="2rem" round :src="userinfo.headimgurl"></van-image>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="16" offset="1">{{datalist.title}}</van-col>
          <van-col span="7" class="mright">
            <div>
              <open-app :ids="ids"></open-app>
            </div>
<!--            <span class="font13 colchose paddzy8" @click="toApp">去APP查看</span>-->
          </van-col>
        </van-row>
        <div class="line"></div>
        <van-form @submit="onSubmit" @failed="failSub">
          <div v-for="(item,key) in datalist.groups" :key="key" class="mt2vh">
            <van-row class="mb1vh">
              <van-col span="19" offset="1" v-if="item.type != 'template'">{{item.title}}</van-col>
<!--              <van-col span="8" v-if="item.type == 'list' && !isEmptyData(item.tempName)">
                <span @click="batchData(item)"><van-icon name="checked" color="#1989f0"/><span class="colchose">批量</span></span>
                <span @click="addData(item,1)" class="ml3"><van-icon name="add" color="#1989F0"/><span class="colchose">添加</span></span>
              </van-col>-->
              <van-col span="4" class="mright paddzy10" v-if="item.type == 'list'" @click="addData(item,2)">
                <van-icon name="add" color="#1989F0"/><span class="colchose">添加</span>
              </van-col>
            </van-row>
            <div v-if="item.type == 'data'">
              <form-display :group="item.fields" :ruleForm="ruleForm" :name="'form'" :tableData="tableData"
                            :tlist="datalist" @getForms="getForms"></form-display>
            </div>
            <div v-if="item.type == 'list' && listinfo.length > 0">
              <div >
                <edit-list :listinfo="listinfo" :items="item" :ruleForm="ruleForm" :tableData="tableData"
                           :tlist="datalist" @getLists="getLists"></edit-list>
              </div>
<!--              <div v-for="(its,ke) in listinfo" :key="ke" class="mt10 font13">
                <div v-if="its.name == item.name">
                  <div v-for="(it,k) in its.data" :key="k">
                    <van-row>
                      <van-col span="20" offset="1" class="mt5" v-for="ite in item.fields" :key="ite.name">
                        &lt;!&ndash;                   @click="toUpdate(item,ke,it)"  &ndash;&gt;
                        <div v-if="ite.visible == true">
                          &lt;!&ndash;                      <edit-list :item="ite" :ruleform="ruleForm" :currentitem="it"></edit-list>&ndash;&gt;
                          <span class="mr10">{{ ite.title }}：</span>
                          <span  class="" v-if="ite.type == 'select'">
                        <span v-if="ite.source.data.length > 0">{{ it[ite.name] }}</span>
                        <span v-else>
                          <span v-if="!isEmptyData(it[ite.name])">{{ it[ite.name].label }}</span>
                        </span>
                      </span>
                          <span  class="" v-else-if="ite.type == 'mutiselect'">
                        <span v-for="(muti,k) in it[ite.name]" :key="k" >
                          <span v-if="ite.source.data.length > 0">{{ muti }}，</span>
                          <span v-else>{{ muti.label }}，</span>
                        </span>
                      </span>
                          <span  class="" v-else>
                        <span v-if="ite.editdis == false">
                          <input v-if="ite.type == 'decimal' || ite.type == 'number'" v-model.number="it[ite.name]" class="bor">
                          <input v-else-if="ite.type == 'date'" type="date" v-model="it[ite.name]" class="bor">
                          <input v-else-if="ite.type == 'datetime'" type="datetime-local" v-model="it[ite.name]" class="bor">
                          <input v-else-if="ite.type == 'time'" type="time" v-model="it[ite.name]" class="bor">
                          <input v-else-if="ite.type == 'phone'" type="tel" v-model="it[ite.name]" class="bor" :maxlength="11">
                          <input v-else type="text" :value="it[ite.name]" class="bor">
                        </span>
                        <span v-else>{{ it[ite.name] }}</span>
                      </span>
                        </div>
                      </van-col>
                      <van-col span="3" @click="deleteItem(its,k)" class="col19 mycursor">删除</van-col>
                    </van-row>
                    <div class="padd10">
                      <div class="linebot"></div>
                    </div>

                  </div>
                </div>
              </div>-->
            </div>
            <div class="lines" v-if="item.type != 'template'"></div>
          </div>
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交</van-button>
          </div>
        </van-form>
      </div>
    </div>
<!--    <van-popup v-model="show" position="bottom" :style="{ height: '100%' }" closeable close-icon-position="top-left">
      <p class="mright colchose mr20" @click="next">完成</p>
      <van-cell :title="batchlist.length == batchs.length?'取消全选':'全选'" icon="" @click="choose"/>
      <div class="paddzy15">
        <van-checkbox-group v-model="batchs" ref="batch">
          <van-checkbox :name="it.value" class="mt10" v-for="it in batchlist" :key="it.value">{{ it.label }}</van-checkbox>
        </van-checkbox-group>
      </div>
    </van-popup>-->
  </div>
</template>

<script>

import qs from 'qs';
import {get} from '@/utils/request.js';
import { Toast } from 'vant';
import {getDicVal, submitForm} from "@/api/api.js";
import mixins from '@/mixins'
import formDisplay from "@/components/formDisplay";
import addList from "@/components/addList";
import success from "@/components/success";
import batchDeal from "@/views/components/batchDeal";
import openApp from "@/components/openApp";
import editList from "@/components/editList";

export default {
  name: 'Home',
  mixins:[mixins],
  components:{
    formDisplay,
    addList,
    success,
    batchDeal,
    openApp,
    editList,
  },
  data(){
    return{
      radio:"",
      checkboxGroup: [],
      result:[],
      title:'',
      ruleForm:{
        user:"",
        taskId: {},
      },//提交数据
      list:{
        field:""
      },
      datalist: "",//获取用户需要填写的表单数据
      userinfo:{},//用户信息
      taskid:"",//提交时需要的taskid
      datainfo:{},//提交之后重新加载获取的data中的数据
      code:"",//111
      state:"",//af1543f0063e4aeab32e30d830daade0
      currentDate: new Date(),
      currentTime:"",
      currentDateTime: new Date(),
      leng:0,//check的长度
      /*timeitem:"",//
      pattern:/^[1][3,4,5,7,8][0-9]{9}$/,*/
      datas: {},
      listinfo: [],//存储列表里添加的数据//更改单选多选后的数据
      listinfos: [],//存储列表里添加的未更改的数据
      formlist: [],//当前要修改的列表的表单数据
      grouplist: {},//当前要修改的列表数据
      nowkey: 0,//当前要修改的列表数据的位置
      dis: 0,//
      show: false,//展示批量列表
      batchlist: [],//批量选择的关联字段的内容列表
      batchs: [],//批量选择的值
      templist: [],//模板列表
      currenttemp: [],//当前模板
      visible: true,
      diclist: [],
      ids: {
        dataId: "",
        appId: "",
        companyId: "",
        upDataId: "",
        FiledStatus: "",
      },
      tableData: {file: []},
    }
  },
  created(){

  },
  mounted() {
    let flag = this.isWeixin();
    if (flag){
      let local = localStorage.getItem('listinfo')
      if (local!=null&&local!=undefined&&local!=''){
        localStorage.removeItem('listinfo');
      }else {
        localStorage.removeItem('listinfo');
      }
      this.listinfo = [];
      this.getCode();
    }else {
      this.$router.push('notwx');
    }
    /*let local = localStorage.getItem('listinfo')
    if (local!=null&&local!=undefined&&local!=''){
      localStorage.removeItem('listinfo');
    }else {
      localStorage.removeItem('listinfo');
    }
    this.listinfo = [];
    this.datalist = JSON.parse(this.getForm().formDef);
    //this.dealList();
    let groups = this.datalist.groups;
    for (let i in groups){
      let item = groups[i];
      for (let k in item.fields){for (let j in groups[i].fields){
        let items = groups[i].fields[j];
        items.visible = true;
        items.editdis = false;
      }
      if (item.type == 'list'){
        this.diclist = [];
          let ite = item.fields[k];
          if (ite.source.type == 'dic' && ite.source.from != ''){
            this.getdic(ite.source.from);
          }
        }
      }
    }
    console.log("form",this.datalist);
    //let groups = this.datalist.groups;
    if (groups!=null&&groups!=''&&groups.length!=0){
      this.setRuleForm(this.list);
    }*/
  },
  methods:{
    toApp(){
      //let url = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.fourbull.pm';
      let url = "sixu://sixu.work?dataId="+this.ids.dataId+"&appId="+this.ids.appId+"&companyId="+this.ids.companyId+"&FiledStatus="+this.ids.FiledStatus+"&upDataId="+this.ids.upDataId;
      window.location.href = url;
    },
    getCode(){
      /*let href = "https://sixu.work/task?code=011ZWmFa1nt8MC0eYKFa1b3edY0ZWmFS&state=be322bb49a15482eb853b7c26149982b"
      if (href.indexOf("state") != -1){
        this.code= qs.parse(href.split("#")[0].split("?")[1]).code;
        this.state = qs.parse(href.split("#")[0].split("?")[1]).state;
        console.log("code",this.code);
        console.log("state",this.state);
      }*/
      if (window.location.href.indexOf("state") != -1){
        this.code = qs.parse(window.location.href.split("#")[0].split("?")[1]).code;
        this.state = qs.parse(window.location.href.split("#")[0].split("?")[1]).state
      }
      if (this.code){
        //调用接口，获取用户信息,获取到openid当作token存入本地存储中
        //localStorage.setItem(token, JSON.stringify(tokenObj))
        get('/sso/auth/authwx/'+this.code+'/'+this.state).then(res=>{
        if (res!=null&&res.data!=null&&res.data.content!=null){
          let contents = res.data.content;
          //alert(JSON.stringify(contents))
          //console.log('forms',contents);
          this.userinfo = contents.user;
          localStorage.removeItem("token")
          localStorage.setItem("token",this.userinfo.openid);
          this.taskid = contents.taskId;
          if (!this.isEmptyData(contents.form)){
            this.ids.companyId = contents.form.companyId;
            this.ids.appId = contents.form.appId;
            this.ids.FiledStatus = 'RUN';
            if (!this.isEmptyData(contents.form.formDef)){
              this.datalist = JSON.parse(contents.form.formDef);
              //alert(JSON.stringify(this.datalist))
            }
          }
          if (!this.isEmptyData(contents.data)){
            this.ids = {
              dataId: contents.data.dataId,
              appId: contents.data.appId,
              FiledStatus: contents.data.FiledStatus,
              upDataId: contents.data.upDataId,
            }
          }
          /*if (!this.isEmptyData(contents.form.dataDef)) {//有dataDef这个参数
            let def = JSON.parse(contents.form.dataDef);
            if (!this.isEmptyData(def.viewData.visible)) {
              this.datadef.visible = def.viewData.visible;
              this.visible = true;
              //console.log("d6666",this.visible);
            } else {
              this.visible = false;//没有可见可编辑配置
              //console.log("datadef",this.visible);
            }
          }*/
          let groups = this.datalist.groups;
          let dic = [];
          for (let i in groups){
            for (let j in groups[i].fields){
              let items = groups[i].fields[j];
              items.visible = true;
              items.editdis = false;
              items.editdis = !items.editable;
              /*if (this.visible == true){
                if (!this.isEmptyData(this.datadef.visible[items.name])){
                  if ( this.datadef.visible[items.name].indexOf("visible") === -1){
                    items.visible = false;//不可见
                  }
                  if (this.datadef.visible[items.name].indexOf('edit') === -1){
                    items.editdis = true;//不可编辑
                  }
                }
              }*/
              if (items.source.type == 'dic' && items.source.from != ''){
                dic.push(items.source.from);
              }
            }

            let item = groups[i];
            if (item.type == 'list'){
              this.diclist = [];
              for (let k in item.fields){
                let ite = item.fields[k];
                if (ite.source.type == 'dic' && ite.source.from != ''){
                  this.getdic(ite.source.from);
                }
              }
              this.tableData[item.name] = this.datainfo[item.name];
            }
          }
          const dictype = new Set(dic)
          let dics = [...dictype]

          if (dics.length > 0){
            for (let n in dics){
              this.getdic(dics[n]);
            }
          }
          //this.dealList();
          //console.log('form',this.datalist);
          delete this.tableData.file;
          this.listinfo = [];
          if (groups!=null&&groups!=''&&groups.length!=0){
            this.setRuleForm(this.list);
          }
          if(contents.data!=null&&contents.data!=''){
            this.datainfo = contents.data;
            this.setRuleForm(this.list);
          }
        }else {
          /*alert(JSON.stringify(res.data));*/
          if (res.data.errorCode == "500"){
            Toast.fail(res.data.errorMsg);
            this.dis = 3;
              /*if (res.data.errorMsg == "数据不存在！"){}else {

            }*/
          }else {
            Toast.fail(res.data.errorMsg);
          }
        }
       })
      }
    },
    //赋值ruleForm
    setRuleForm(vdata){
      let data={};//
      //this.listinfo = [];
      if (this.datalist.groups){
        for(let i in this.datalist.groups){
          let group=this.datalist.groups[i];
          //data[group.name]=group.title;
          if (group.fields){
            if(group.type=="data"||group.type == 'list'){
              for (let j in group.fields){
                let item = group.fields[j];
                if (item.type == "date") {
                  if (item.source.defaultype == '1'){
                    data[item.name] = item.source.default;
                  }else if (item.source.defaultype == '0'){
                    data[item.name] = this.formatDate(this.currentDate);
                    //console.log('1',data[item.name])
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else if (item.type == "time") {
                  if (item.source.defaultype == '1'){
                    data[item.name] = item.source.default;
                  }else if (item.source.defaultype == '0'){
                    data[item.name] = this.currentTime;
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else if (item.type == "datetime") {
                  if (item.source.defaultype == '1'){
                    data[item.name] = item.source.default;
                  }else if (item.source.defaultype == '0'){
                    data[item.name] = this.formatDateTime(this.currentDateTime);
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else if (item.type == 'txt' || item.type == 'decimal' || item.type == 'number' || item.type == 'phone'){
                  if (item.source.default !=null && item.source.default !=''){
                    data[item.name] = item.source.default;
                  }else {
                    data[item.name]=vdata[item.name];
                  }
                }else {
                  data[item.name]=vdata[item.name];
                }
                if (this.datainfo!=null&&this.datainfo!=''&&this.datainfo.dataId!=null){
                  let k = item.name;
                  if (item.type == "date"){
                    this.date = this.datainfo[k];
                    //this.currentDate = this.datainfo[k];
                    data[item.name] = this.datainfo[k];
                  }else if (item.type == "select") {
                    if (this.datainfo[k]!=null&&this.datainfo[k]!=''){
                      data[item.name] = this.datainfo[k].value;
                    }
                  }else if (item.type == "mutiselect") {
                    data[item.name] = [];
                    for (let aa in this.datainfo[k]){
                      data[item.name].push(this.datainfo[k][aa].value)
                    }
                  }else if (item.type == "time") {
                    this.currentTime = this.datainfo[k];
                    this.time = this.datainfo[k];
                    data[item.name] = this.datainfo[k];
                  }else if (item.type == "datetime") {
                    //this.currentDateTime = this.datainfo[k];
                    this.datetime = this.datainfo[k];
                    data[item.name] = this.datainfo[k];
                  }else {
                    data[item.name] = this.datainfo[k];
                  }
                }
              }
            }
          }
          if (group.type == 'list'){
            this.listinfo.push({name: group.name,data: []})
            if (this.datainfo[group.name]){
              let index = this.listinfo.findIndex(x => x.name == group.name);
              if (index !== -1){
                this.listinfo[index].data = this.datainfo[group.name] ;
              }
            }
            this.listinfos = this.listinfo;
            localStorage.removeItem('listinfo')
            const data = this.listinfos;
            const datas = JSON.stringify(data);
            localStorage.setItem('listinfo',datas);
          }
        }
      }
      this.ruleForm = data;
      //this.errmsg = data;
      //console.log("shujusss",this.ruleForm);
    },
    //模板批量添加，暂不使用（模板暂未进行添加操作，目前只进行数据展示）
    batchData(item){
      //console.log("item",item);
      this.show = true;
      this.batchlist = [];
      if (!this.isEmptyData(item.tempField)){
        for (let i in item.fields){
          let its = item.fields[i];
          if (its.name == item.tempField){
            this.batchlist = its.source.data;
          }
        }
        this.currenttemp = [];
        for (let k in this.templist){
          let its = this.templist[k];
          if (its.name == item.tempName){
            this.currenttemp = its;
          }
        }
      }
      //console.log('currenttemp',this.currenttemp);
      this.grouplist = this.ruleForm;
      //console.log('ruleform',this.grouplist);
    },
    //模板批量选择下一步
    next(){
      //console.log("choose",this.batchs);
      this.dis = 2;
      this.show = false;
    },
    choose(){
      if (this.batchlist.length == this.batchs.length){
        this.$refs.batch.toggleAll();
      }else {
        this.$refs.batch.toggleAll(true);
      }
    },
    getDefaultval(items){
      for (let i in items.fields){
        let item = items.fields[i];
        if (item.type == "date") {
          if (item.source.defaultype == '1'){
            this.ruleForm[item.name] = item.source.default;
          }else if (item.source.defaultype == '0'){
            this.ruleForm[item.name] = this.formatDate(this.currentDate);
            //console.log('1',data[item.name])
          }else {
            this.ruleForm[item.name]="";
          }
        }else if (item.type == "time") {
          if (item.source.defaultype == '1'){
            this.ruleForm[item.name] = item.source.default;
          }else if (item.source.defaultype == '0'){
            this.ruleForm[item.name] = this.currentTime;
          }else {
            this.ruleForm[item.name]="";
          }
        }else if (item.type == "datetime") {
          if (item.source.defaultype == '1'){
            this.ruleForm[item.name] = item.source.default;
          }else if (item.source.defaultype == '0'){
            this.ruleForm[item.name] = this.formatDateTime(this.currentDateTime);
          }else {
            this.ruleForm[item.name]="";
          }
        }else if (item.type == 'txt' || item.type == 'decimal' || item.type == 'number' || item.type == 'phone'){
          if (item.source.default !=null && item.source.default !=''){
            this.ruleForm[item.name] = item.source.default;
          }else {
            this.ruleForm[item.name]="";
          }
        }else {
          this.ruleForm[item.name]="";
        }
      }
    },
    addData(item){
      this.formlist = [];
      this.formlist = item;
      //this.setRuleForm(this.list);
      this.getDefaultval();
      this.grouplist = {};
      this.grouplist = this.ruleForm;
      this.nowkey = null;
      this.dis = 1;
    },
    //获取添加的列表的数据
    getInfo(){
      if (localStorage.getItem('listinfo')!=null && localStorage.getItem('listinfo')!=undefined){
        this.listinfo = JSON.parse(localStorage.getItem('listinfo'));
        this.listinfos = JSON.parse(localStorage.getItem('listinfo'));
        //console.log("listinfo",this.listinfo)
        this.getlists();
        /*for (let n in this.listinfo){
          let info = this.listinfo[n];
          for (let k in this.datalist.groups){
            let group = this.datalist.groups[k];
            if (group.type == 'list' && group.name == info.name){
              for (let j in info.data){
                let its = info.data[j];
                for (let k in group.fields){
                  let sou = group.fields[k];
                  if (sou.type == 'select'){
                    if (its[sou.name]){
                      let da = its[sou.name];
                      if (sou.source.data.length != 0){
                        let dat = sou.source.data;
                        for (let m in dat){
                          if (da == dat[m].value){
                            its[sou.name]= dat[m];
                          }
                        }
                      }
                      if (sou.source.type == 'dic' && sou.source.from != '') {
                        //判断是否有配置条件，若有如下：
                        if (!this.isEmptyData(sou.source.conditions) && sou.source.conditions.length > 0) {
                          let dic = sou.source.conditions[0].field;
                          if (!this.isEmptyData(this.ruleForm[dic])) {
                            let data = {
                              dicType: sou.source.from,
                              pdicCode: this.ruleForm[dic].value
                            }
                            getDicVal(data).then(res => {
                              if (res != null && res.data != null && res.data.content != null) {
                                if (res.data.content.length > 0) {
                                  let con = res.data.content;
                                  for (let l in con) {
                                    if (this.ruleForm[sou.name] == con[l].value) {
                                      this.ruleForm[sou.name] = {label: con[l].label, value: con[l].value};
                                    }
                                  }
                                }
                              }
                            })
                          }
                        } else {
                          for (let i in this.diclist) {
                            let it = this.diclist[i];
                            if (it.type == sou.source.from) {
                              for (let j in it.list) {
                                if (da == it.list[j].value) {
                                  its[sou.name] = {label: it.list[j].label, value: it.list[j].value};
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }else if (sou.type == 'mutiselect'){
                    let datas = [];
                    if (its[sou.name]){
                      for (let p in its[sou.name]){
                        let da = its[sou.name][p];
                        if (da.label){
                          da = its[sou.name][p];
                        }else {
                          if (sou.source.data.length != 0){
                            let dat = sou.source.data;
                            for (let d in dat){
                              if (da == dat[d].value){
                                datas.push(dat[d]);
                              }
                            }
                          }
                          if (sou.source.type == 'dic' && sou.source.from != ''){
                            for (let i in this.diclist){
                              let it = this.diclist[i];
                              if (it.type == sou.source.from){
                                for (let j in it.list){
                                  if (da == it.list[j].value){
                                    datas.push({label: it.list[j].label, value: it.list[j].value});
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      its[sou.name] = datas;
                    }
                  }
                }
              }
            }
          }
        }*/
      }
      //console.log("list111",this.listinfo);
    },
    toUpdate(item,ke,itt){
      this.formlist = [];
      this.formlist = item;
      this.grouplist = {};
      let i = itt;
      for (let z in item.fields){
        let its = item.fields[z];
        if (its.type == 'select'){
          if (its.source.data.length != 0){
            if (i[its.name]){
              i[its.name] = i[its.name].value;
            }
          }else {
            if (i[its.name]){
              i[its.name] = i[its.name].label;
            }
          }
        }else if (its.type == 'mutiselect'){
          if (i[its.name].length != 0){
            if (its.source.data.length != 0){
              for (let a in i[its.name]){
                let it = i[its.name][a];
                i[its.name][a] = it.value;
              }
            }else {
              for (let a in i[its.name]){
                let it = i[its.name][a];
                i[its.name][a] = it.label;
              }
            }
          }
        }
      }
      this.grouplist = i;
      let da = {key: ke}
      this.$store.commit('addlist/CLEAR_ADDINFO');
      this.$store.commit('addlist/SET_ADDINFO',da);
      //console.log("thke",this.nowkey)
      this.dis = 1;
    },
    deleteItem(it,ke){
      for (let i in this.listinfo){
        let info = this.listinfo[i];
        if (info.name == it.name){
          info.data.splice(ke,1);
        }
      }
      /*this.listinfo.splice(ke,1);
      this.listinfos.splice(ke,1);*/
      let data1 = this.listinfo;
      let data = JSON.stringify(data1)
      localStorage.removeItem('listinfo');
      localStorage.setItem('listinfo',data);
    },
    getlists(){
      for (let n in this.listinfo){
        let info = this.listinfo[n];
        for (let k in this.datalist.groups){
          let group = this.datalist.groups[k];
          if (group.type == 'list' && group.name == info.name){
            for (let j in info.data){
              let its = info.data[j];
              for (let k in group.fields){
                let sou = group.fields[k];
                if (sou.type == 'select'){
                  if (its[sou.name]){
                    let da = its[sou.name];
                    if (sou.source.data.length != 0){
                      let dat = sou.source.data;
                      for (let m in dat){
                        if (da == dat[m].value){
                          its[sou.name]= dat[m];
                        }
                      }
                    }
                    if (sou.source.type == 'dic' && sou.source.from != ''){
                      //判断是否有配置条件，若有如下：
                      if (!this.isEmptyData(sou.source.conditions) && sou.source.conditions.length > 0){
                        let dic = sou.source.conditions[0].field;
                        if (!this.isEmptyData(this.ruleForm[dic])){
                          let data = {
                            dicType: sou.source.from,
                            pdicCode: this.ruleForm[dic].value
                          }
                          getDicVal(data).then(res => {
                            if (res != null && res.data != null && res.data.content != null){
                              if (res.data.content.length > 0){
                                let con = res.data.content;
                                for (let l in con){
                                  if (this.ruleForm[sou.name] == con[l].value){
                                    this.ruleForm[sou.name] = {label: con[l].label,value: con[l].value};
                                  }
                                }
                              }
                            }
                          })
                        }
                      }else {
                        for (let i in this.diclist) {
                          let it = this.diclist[i];
                          if (it.type == sou.source.from) {
                            for (let j in it.list) {
                              if (da == it.list[j].value) {
                                its[sou.name] = {label: it.list[j].label, value: it.list[j].value};
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }else if (sou.type == 'mutiselect'){
                  let datas = [];
                  if (its[sou.name]){
                    for (let p in its[sou.name]){
                      let da = its[sou.name][p];
                      if (da.label){
                        da = its[sou.name][p];
                      }else {
                        if (sou.source.data.length != 0){
                          let dat = sou.source.data;
                          for (let d in dat){
                            if (da == dat[d].value){
                              datas.push(dat[d]);
                            }
                          }
                        }
                        if (sou.source.type == 'dic' && sou.source.from != ''){
                          for (let i in this.diclist){
                            let it = this.diclist[i];
                            if (it.type == sou.source.from){
                              for (let j in it.list){
                                if (da == it.list[j].value){
                                  datas.push({label: it.list[j].label, value: it.list[j].value});
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    its[sou.name] = datas;
                  }
                }
                if (sou.grouptype == 'number' || sou.grouptype == 'calc'){
                  if (!this.isEmptyData(its[sou.name])){
                    its[sou.name] = Number(its[sou.name]);
                  }
                }
              }
            }
          }
        }
      }
    },
    onSubmit(){//提交表单
      this.getlists();
      let values = [];
      for(let t in this.datalist.groups){
        let grou = this.datalist.groups[t];
        if (grou.type == 'list'){
          this.ruleForm[grou.name] = [];
          for (let i in this.listinfo){
            let its = this.listinfo[i];
            if (its.name == grou.name){
              this.ruleForm[grou.name] = its.data;
            }
          }
          //this.ruleForm[grou.name] = this.listinfo;
          for (let l in grou.fields){
            let lit = grou.fields[l];
            delete this.ruleForm[lit.name];
          }
        }else {
          for(let n in grou.fields){
            let items= grou.fields[n];
            if (items.type=="select"){
              if (this.ruleForm[items.name]){
                let valu = this.ruleForm[items.name];
                let datas = items.source.data
                if (datas!=null&&datas.length>0){
                  for (let m in datas){
                    let val=datas[m];
                    if (val.value==valu){
                      this.ruleForm[items.name]=val;
                    }
                  }
                }
                if (items.source.type == 'dic' && items.source.from != ''){
                  //判断是否有配置条件，若有如下：
                  if (!this.isEmptyData(items.source.conditions) && items.source.conditions.length > 0){
                    let dic = items.source.conditions[0].field;
                    if (!this.isEmptyData(this.ruleForm[dic])){
                      let data = {
                        dicType: items.source.from,
                        pdicCode: this.ruleForm[dic].value
                      }
                      getDicVal(data).then(res => {
                        if (res != null && res.data != null && res.data.content != null){
                          if (res.data.content.length > 0){
                            let con = res.data.content;
                            for (let l in con){
                              if (this.ruleForm[items.name] == con[l].value){
                                this.ruleForm[items.name] = {label: con[l].label,value: con[l].value};
                              }
                            }
                          }
                        }
                      })
                    }
                  }else {
                    for (let i in this.diclist){
                      let it = this.diclist[i];
                      if (it.type == items.source.from){
                        for (let j in it.list){
                          if (this.ruleForm[items.name] == it.list[j].value){
                            this.ruleForm[items.name] = {label: it.list[j].label,value: it.list[j].value};
                          }
                        }
                      }
                    }
                  }
                }
              }
            } else if (items.type=="mutiselect"){
              if (this.ruleForm[items.name]){
                for(let i in this.ruleForm[items.name]){
                  let val = this.ruleForm[items.name][i];
                  if (items.source.data!=null&&items.source.data!=''){
                    for (let j in items.source.data){
                      let vall = items.source.data[j];
                      if (val == vall.value){
                        values.push(vall) ;
                      }
                    }
                  }
                  if (items.source.type == 'dic' && items.source.from != ''){
                    for (let i in this.diclist){
                      let it = this.diclist[i];
                      if (it.type == items.source.from){
                        for (let j in it.list){
                          if (val == it.list[j].value){
                            values.push({label: it.list[j].label, value: it.list[j].value});
                          }
                        }
                      }
                    }
                  }
                }
              }
              this.ruleForm[items.name] = values
            }
            if (items.grouptype == "number"||items.grouptype == "calc"){
              if (!this.isEmptyData(this.ruleForm[items.name])){
                this.ruleForm[items.name] = Number(this.ruleForm[items.name])
              }
            }
          }
        }
      }
      this.ruleForm.user=this.userinfo;
      this.ruleForm.taskId = this.taskid;
      //console.log("ruleForm",this.ruleForm);
      submitForm(this.ruleForm).then(res=>{
        if (res!=null&&res.data!=null&&res.data.content!=null){
          if (res.data.content==1){
            Toast.success('提交成功');
            this.lowering(this.ruleForm);
            //window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5ef0e4fab3c1525d&redirect_uri=https%3A%2F%2Fsixu.work%2Ftask&response_type=code&scope=snsapi_userinfo&state="+this.state+"#wechat_redirect"
            localStorage.removeItem('listinfo');
            this.dis = 99;
          }
        }else {
          Toast.fail(res.data.errorMsg);
          this.lowering(this.ruleForm);
        }
      });
    },
    lowering(da){
      for(let t in this.datalist.groups){
        let grou = this.datalist.groups[t];
        for(let n in grou.fields){
          let items= grou.fields[n];
          if (items.type=="select"){
            if (da[items.name] != null && da[items.name] != ''){
              da[items.name] = da[items.name].value;
            }
          }
          if (items.type=="mutiselect"){
            let values = [];
            if (da[items.name]){
              for(let i in da[items.name]){
                values.push(da[items.name][i].value);
              }
            }
            da[items.name] = values
          }
        }
      }
      //console.log("daaaa",da)
      //return da;
    },
    failSub(){
      Toast("请检查必填项是否全部填写！")
    },
    changedis(d){
      this.dis = d;
      this.getInfo();
    },
    getdic(val){
      let flag = true;
      if (this.diclist.length > 0){
        let t = this.diclist.findIndex(x => x.type == val);
        if (t !== -1){
          flag = false;
          return
        }
      }
      if (flag){
        let data = {
          dicType: val,
        }
        getDicVal(data).then(res => {
          //console.log("dic",res);
          if (res != null && res.data != null && res.data.content != null){
            if (res.data.content.length > 0){
              this.diclist.push({type: val, list: res.data.content});
            }
          }
        })
      }
    },
    getLists(data,name,k,items){
      for (let i in this.listinfo){
        let its = this.listinfo[i];
        if(its.name == items.name){
          its = data;
        }
      }
      localStorage.removeItem('listinfo');
      console.log("listinfo-edit",this.listinfo);
      localStorage.setItem('listinfo',JSON.stringify(this.listinfo));
    },
    getForms(data,name){
      this.ruleForm[name] = data;
      //console.log("file---",this.ruleForm[name]);
      //console.log("ruleForm--",this.ruleForm);
    },
    //模板编辑--目前暂不使用
    dealList(){
      let groups = this.datalist.groups;
      this.templist = [];
      for (let i in groups){
        //console.log('llll',groups[i]);
        if (groups[i].type == "template"){
          this.templist.push({name: groups[i].name, data: groups[i].fields});
        }
      }
      if (this.templist.length != 0){
        for (let j in groups){
          if (groups[j].type == 'list' && !this.isEmptyData(groups[j].tempName)){
            for (let l in this.templist){
              if (groups[j].tempName == this.templist[l].name){
                for (let n in this.templist[l].data){
                  groups[j].fields.push(this.templist[l].data[n]);
                }
              }
            }
          }
        }
      }
      //console.log('form',this.datalist);
    },
  },
}
</script>

<style lang="less" scoped>
.wid{width: 100%;}
.mt1{margin-top: 5px;}
.mt4vh{margin-top: 4vh;}
.mt5vh{margin-top: 5vh;}
.mt2vh{margin-top: 2vh;}
.mt10{margin-top: 10px;}
.mb1vh{margin-bottom: 1vh;}
.font13{font-size: 13px;}
.line1{border-top: #f2f2f2 solid 1px;width: 88vw;margin: 8px auto;}
.lines{border-top: #f2f2f2 solid 1px;width: 92vw;margin: 20px auto;}
//.mr{margin-right: 18vw;}
.mr10{margin-right: 10px;}
.mright{text-align:right;}
.mcenter{text-align: center;}
//.btn{text-align: center;margin: 4vh auto;}
.txt{vertical-align: middle;}
/deep/ .van-dropdown-menu{box-shadow:0 0 0 #f2f2f2!important;}
/deep/ .van-cell{padding: 4px 16px;}
.col19{color: #1989f0;}
</style>
