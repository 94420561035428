<template>
  <div>
    <div v-for="items in group" :key="items.name">
      <div v-if="items.visible == true">
        <van-row>
          <van-col :span="22" :offset="1">
            <van-row>
              <van-col :span="24" >
                <van-field v-model="items.title" :required="items.required" rows="1" autosize type="textarea" readonly></van-field>
                <!--                  <span style="color: red;font-size: 18px;" v-if="items.required==true">*</span><span>{{items.title}}</span>-->
              </van-col>
            </van-row>
            <!--文本控件-->
            <div v-if="items.grouptype=='txt'">
              <div v-if="items.type=='txt'">
                <van-field v-model="ruleForm[items.name]" :maxlength="items.length" placeholder="请输入"
                           clearable :rules="items.required?[{required:true,message:'必填'}]:[{}]" autosize type="textarea" :disabled="items.editdis"></van-field>
              </div>
              <div v-else-if="items.type=='phone'">
                <van-field type="tel" v-model.number="ruleForm[items.name]" maxlength="11" :disabled="items.editdis"
                           placeholder="请输入电话号码" :rules="items.required?[{ required: true, message: '必填' },{pattern,message: '电话号码输入有误'}]:[{}]" clearable></van-field>
              </div>
              <div v-else-if="items.type == 'link'">
                <van-field :maxlength="items.length" placeholder="请输入" disabled
                           clearable :rules="items.required?[{required:true,message:'必填'}]:[{}]">
                  <template #input>
                    <a :href="ruleForm[items.name]" target="_blank">{{ruleForm[items.name]}}</a>
                  </template>
                </van-field>
              </div>
              <div v-else>
                <van-field v-model="ruleForm[items.name]" :maxlength="items.length" placeholder="请输入" :disabled="items.editdis"
                           clearable :rules="items.required?[{required:true,message:'必填'}]:[{}]"></van-field>
              </div>
            </div>
            <!--数字控件-->
            <div v-else-if="items.grouptype=='number'">
              <div v-if="items.type=='number'">
                <van-field type="digit" v-model.number="ruleForm[items.name]" maxlength="10" :disabled="items.editdis"
                           placeholder="请输入整数" :rules="items.required?[{required:true,message:'必填'}]:[{}]" clearable></van-field>
              </div>
              <div v-else-if="items.type=='decimal'">
                <van-field type="number" v-model.number="ruleForm[items.name]" maxlength="10" :disabled="items.editdis"
                           placeholder="请输入一个数（可小数）" :rules="items.required?[{required:true,message:'必填'}]:[{}]" clearable></van-field>
              </div>
            </div>
            <!--时间控件-->
            <div v-else-if="items.grouptype == 'date'">
              <div v-if="items.type=='date'">
                <date-element :items="items" :ruleForm="ruleForm" :name="'add'"></date-element>
<!--                <van-field v-model="ruleForm[items.name]" @click="opentime(items.name,1)"
                           placeholder="请选择日期" :rules="items.required?[{ required: true, message: '必填' }]:[{}]" :disabled="items.editdis"></van-field>-->
              </div>
              <div v-else-if="items.type=='time'">
                <time-element :items="items" :ruleForm="ruleForm" :name="'add'"></time-element>
<!--                <van-field v-model="ruleForm[items.name]" @click="opentime(items.name,2)"
                           placeholder="请选择时间" :rules="items.required?[{ required: true, message: '必填' }]:[{}]" :disabled="items.editdis"></van-field>-->
              </div>
              <div v-else-if="items.type=='datetime'">
                <datetime-element :items="items" :ruleForm="ruleForm" :name="'add'"></datetime-element>
<!--                <van-field v-model="ruleForm[items.name]" @click="opentime(items.name,3)"
                           placeholder="请选择日期时间" :rules="items.required?[{ required: true, message: '必填' }]:[{}]" :disabled="items.editdis"></van-field>-->
              </div>
            </div>
            <!--联系人控件-->
            <div v-else-if="items.grouptype=='contacts'">
              <van-field v-model="ruleForm[items.name].nickName" :maxlength="items.length" placeholder="请输入"
                         clearable :rules="items.required?[{required:true,message:'必填'}]:[{}]" :disabled="true"></van-field>
            </div>
            <!--手写签名控件-->
            <div v-else-if="items.grouptype == 'sign'">
              <sign-element :items="items" :ruleForm="ruleForm" :name="name"></sign-element>
            </div>
           <!-- 选择控件-->
            <div v-else-if="items.grouptype == 'select'">
              <!-- 单选 -->
              <div v-if="items.type=='select'">
                <select-element :items="items" :ruleForm="ruleForm" :name="name"></select-element>
              </div>
              <!-- 多选-->
              <div v-else-if="items.type == 'mutiselect'">
                <mutiselect-element :items="items" :ruleForm="ruleForm" :name="name"></mutiselect-element>
              </div>
            </div>
            <!--图片和文件-->
            <div v-else-if="items.grouptype == 'img'">
<!--              <img-file :items="items" :ruleForm="ruleForm"></img-file>-->
              <img-element :items="items" :ruleForm="ruleForm" v-bind="$attrs" :file="ruleForm[items.name]" @getForms="getForms"></img-element>
            </div>
            <div v-else-if="items.grouptype == 'file'">
<!--              <img-file :items="items" :ruleForm="ruleForm"></img-file>-->
              <img-element :items="items" :ruleForm="ruleForm" v-bind="$attrs" :file="ruleForm[items.name]" @getForms="getForms"></img-element>
            </div>
            <!--计算控件-->
            <div v-else-if="items.grouptype == 'calc'">
              <div v-if="items.type == 'calc'">
                <calc-element :ruleForm="ruleForm" :item="items" :name="name" v-bind="$attrs" :calc="'add'"></calc-element>
              </div>
            </div>
            <div v-else>
              <van-field v-model="ruleForm[items.name]" :maxlength="items.length" placeholder="请输入"
                         clearable :rules="items.required?[{required:true,message:'必填'}]:[{}]"></van-field>
            </div>
          </van-col>
        </van-row>
        <div class="line1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins'
import mutiselectElement from "@/views/components/details/mutiselectElement";
import selectElement from "@/views/components/details/selectElement";
import imgFile from "@/views/components/details/imgFile";
import signElement from "@/views/components/details/signElement";
import calcElement from "@/views/components/details/calcElement";
import dateElement from "@/views/components/details/dateElement";
import datetimeElement from "@/views/components/details/datetimeElement";
import timeElement from "@/views/components/details/timeElement";
import imgElement from "@/views/components/details/imgElement";

export default {
  name: "formDisplay",
  mixins: [mixins],
  props:['group','ruleForm','name'],
  components: {
    mutiselectElement,
    selectElement,
    imgFile,
    signElement,
    calcElement,
    dateElement,
    timeElement,
    datetimeElement,
    imgElement,
  },
  data(){
    return{
      date:"",//日期年月日
      show:false,//
      time:"",//时间
      show1:false,//
      datetime:"",//日期时间
      show2:false,
      currentDate: new Date(),
      currentTime:"",
      currentDateTime: new Date(),
      timeitem:"",//
      pattern:/^[1][3,4,5,7,8][0-9]{9}$/,
    }
  },
  mounted() {
    this.formatTime();
    //console.log("group-sssss",this.group);
  },
  methods:{
    getDate(value){
      this.ruleForm[this.timeitem]=this.formatDate(value);
      this.show=false;
    },
    getTime(times){
      this.ruleForm[this.timeitem] = times;
      this.show1 = false;
    },
    getDateTime(datetime){
      this.ruleForm[this.timeitem] = this.formatDateTime(datetime);
      this.show2 = false;
    },
    cancel(){
      this.show=false;
    },
    opentime(item,n){
      this.timeitem=item;
      if (n == 1){
        this.show = true;
      }else if (n == 2){
        this.show1 = true;
      }else if (n == 3){
        this.show2 = true;
      }
    },
    getForm(){
      //console.log("ruleForm",this.ruleForm);
      this.$emit('getRuleForm',this.ruleForm);
    },
    getForms(data,name){
      this.ruleForm[name] = data;
      //console.log("ruleForm[file]",this.ruleForm[name]);
      this.$emit('getForms',this.ruleForm[name],name);
    }
  }
}
</script>

<style scoped>
.line1{border-top: #f2f2f2 solid 1px;width: 90vw;margin: 8px auto;}
/*.line{border-top: #f2f2f2 solid 1px;width: 92vw;margin: 20px auto;}*/
/deep/ .van-dropdown-menu{box-shadow:0 0 0 #f2f2f2!important;}
/deep/ .van-cell{padding: 8px 12px;}
.col19{color: #1989f0;}
.van-radio__label--disabled {color: #333!important;}
</style>