<template>
  <div>
    <van-nav-bar title="编辑" :border="false">
      <template #left>
        <van-icon name="arrow-left" color="#000000" size="18" @click="onClickLeft"/>
      </template>
<!--      <template #right>
        <span @click="save" class="col19">完成</span>
      </template>-->
    </van-nav-bar>
    <form-display :group="group.fields" :ruleForm="ruleForm" :name="'list'" :dis="'add'" v-bind="$attrs" @getForms="getForms"></form-display>
    <van-row class="mtb20">
      <van-col :span="12" :offset="6">
        <van-button round block type="info"  @click="save">完成</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
//列表数据添加界面
import formDisplay from "@/components/formDisplay";
import mixins from '@/mixins'

export default {
  name: "addList",
  props: ['group','ruleForm','batch'],
  mixins: [mixins],
  components: {
    formDisplay,
  },
  data(){
    return{
      //ruleForm: {},
      listinfo: [],
      isupdate: false,//是否为修改
      key: "",
    }
  },
  created() {
    if (localStorage.getItem('listinfo')){
      this.listinfo = JSON.parse(localStorage.getItem('listinfo'));
    }
  },
  mounted() {
  },
  methods:{
    getForms(data,name){
      this.ruleForm[name] = data;
      //console.log("ruleform-add",this.ruleForm)
    },
    save(){
      /*this.key = this.$store.getters.addinfo.key;
      if (!this.isEmptyData(this.key)){
        this.listinfo[this.key] = this.ruleForm;
      }else {}*/
      for (let i in this.listinfo){
        let item = this.listinfo[i];
        if (item.name == this.group.name){
          item.data.push(this.ruleForm);
        }
      }
      //this.listinfo.push(this.ruleForm);

      //console.log("addrule",this.ruleForm);
      localStorage.removeItem('listinfo');
      //console.log("add-listinfo",this.listinfo);
      localStorage.setItem('listinfo',JSON.stringify(this.listinfo));
      //this.$store.commit('addlist/SET_ADDINFO',this.listinfo);
      //console.log("addrule",localStorage.getItem('listinfo'));
      this.$emit('changedis',0);
    },
    onClickLeft(){
      //console.log("ruleform-left-add",this.ruleForm);
      this.$emit('changedis',0);
    },
    initData(){
      this.isupdate = false;
    },
  },
}
</script>

<style scoped>
.col19{color: #1989f0;}
.mcenter{text-align: center;}
</style>