<template>
  <div>
    <div v-if="dis == 'edit'">
      <van-field :label="items.title"  label-width="120" label-align="right">
        <template #input>
          <div v-if="items.type == 'img'">
            <van-uploader v-model="fileList" :max-size="30*1024*1024" multiple :after-read="getImg" @oversize="onOversize($event,1)"
                          :disabled="items.editdis" :deletable="!items.editdis" @delete="deleteFile" :preview-options="{closeable: true}"/>
          </div>
          <div v-else-if="items.type == 'file'" class="ml20">
            <van-uploader v-model="fileList" :max-size="50*1024*1024" :after-read="getImg" @oversize="onOversize($event,2)"
                          :disabled="items.editdis" :deletable="!items.editdis" @delete="deleteFile" @click-preview="toPreview"
                          accept="image/*,.pdf,.ppt,.pptx,.docx,.doc,.xlsx,.xls" :preview-options="{closeable: true}">
              <van-button icon="plus" type="info" size="small">上传文件</van-button>
            </van-uploader>
            <div class="font12 col7f"><span>文件不能超过50M</span>
              <span v-if="items.demoFile&&JSON.stringify(items.demoFile)!=='{}'" class="ml10 colchose" @click="exportDemo">下载模板</span>
            </div>
          </div>
        </template>
      </van-field>
    </div>
    <div v-else>
      <div v-if="items.type == 'img'">
        <van-uploader v-model="fileList" :max-size="30*1024*1024" multiple :after-read="getImg" @oversize="onOversize($event,1)"
                      :disabled="items.editdis" :deletable="!items.editdis" @delete="deleteFile" :preview-options="{closeable: true}"/>
      </div>
      <div v-else-if="items.type == 'file'" class="ml20">
        <van-uploader v-model="fileList" :max-size="50*1024*1024" :after-read="getImg" @oversize="onOversize($event,2)"
                      :disabled="items.editdis" @delete="deleteFile" :deletable="!items.editdis" @click-preview="toPreview"
                      accept="image/*,.pdf,.ppt,.pptx,.docx,.doc,.xlsx,.xls" :preview-options="{closeable: true}">
          <van-button icon="plus" type="info" size="small">上传文件</van-button>
        </van-uploader>
        <div class="font12 col7f mt10"><span>文件不能超过50M</span>
          <span v-if="items.demoFile&&JSON.stringify(items.demoFile)!=='{}'" class="ml10 colchose" @click="exportDemo">下载模板</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import mixins from "@/mixins/index"
import {getUploadToken} from "@/api/api";
import {Toast} from "vant";
import axios from "axios";
export default {
  name: "imgElement",
  mixins: [mixins],
  props: ["items","ruleForm","shareId","companyId","dis","file","keys"],
  data(){
    return{
      //imgList: [],
      fileList: [],
      imgtoken: "",
    }
  },
  mounted() {
    //console.log("shareid",this.shareId);
    //this.getImgToken();
    if (this.dis == 'edit'){
      this.ruleForm[this.items.name] = this.file;
      this.fileList = this.file;
      this.getFile();
      console.log("edit--file",this.fileList)
    }else if (this.dis == 'add'){
      this.ruleForm[this.items.name] = [];
    }else {
      //console.log("第一级",this.ruleForm);
      if (!this.isEmptyData(this.ruleForm[this.items.name])&& this.ruleForm[this.items.name].length > 0){
        this.fileList = this.ruleForm[this.items.name];
        this.getFile();
      }
    }

  },
  methods: {
    getFile(){
      for (let i in this.fileList){
        let its = this.fileList[i];
        its.url = this.imgUrl + "/" + this.companyId + "/" + its.fileName;
        its.file =  new File([],its.fullName,{});
      }
    },
    getImg(file){
      //console.log("addfile",this.fileList);
      let type="";
      let name="";
      if(file.file.name.lastIndexOf(".")!==-1) {
        type = file.file.name.substring(file.file.name.lastIndexOf("."), file.file.name.length);
        name=file.file.name.substring(0,file.file.name.lastIndexOf("."));
      }
      let formdata = new FormData;
      formdata.append("file",file.file);
      getUploadToken(this.shareId).then(res => {
        //console.log("res",res);
        if (res != null && res.data != null && res.data.content != null ){
          formdata.append("sign",res.data.content);
          formdata.append("pathPrefix",this.companyId);
          axios({
            url: this.uploadUrl,
            method:"POST",
            data:formdata,
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }).then(ress => {
            //console.log("res123",ress);
            if (ress != null && ress.data != null && ress.data.content != null){
              this.ruleForm[this.items.name].push({
                dateTime: new Date().getTime(),
                fileName: ress.data.content + type,
                suffix:!this.isEmptyData(type)?type.substring(1,type.length):type,
                name:name,
                fileSize: file.file.size,
                fullName: file.file.name,
                fileId: ress.data.content
              })
              //console.log("imgadd",this.ruleForm[this.items.name])
              if (!this.isEmptyData(this.keys) && this.dis == 'edit'){
                this.$emit("getForms",this.ruleForm[this.items.name],this.items.name,this.keys)
              }else {
                this.$emit("getForms",this.ruleForm[this.items.name],this.items.name)
              }
            }
          })
        }
      })
    },
    deleteFile(file){
      let vals = [];
      vals = this.ruleForm[this.items.name].filter(x => (x.fullName!=file.file.name && x.fileSize != file.file.size))
      this.ruleForm[this.items.name] = vals;
      //console.log("img-dele",this.ruleForm[this.items.name])
    },
    toPreview(file){
      let filess = [];
      filess = this.ruleForm[this.items.name].filter(x => (x.fullName == file.file.name ))
      if (filess[0].suffix == 'docx' || filess[0].suffix == 'doc' || filess[0].suffix == 'pptx' || filess[0].suffix == 'ppt' || filess[0].suffix == 'xlsx' || filess[0].suffix == 'xls'){
        let urls = this.imgUrl + "/" + this.companyId + "/" + filess[0].fileName;
        let file1 = encodeURIComponent(urls);
        let url = "https://view.officeapps.live.com/op/view.aspx?src=" + file1;
        var link = document.createElement('a');
        link.setAttribute('download', "");
        link.setAttribute('target', "_blank");
        link.setAttribute('href', url);
        link.click()
      }
      //console.log("file- pre",filess)
    },
    onOversize(file,num) {
      //console.log(file);
      if (num == 1){
        Toast('图片大小不能超过 30MB');
      }else {
        Toast('上传文件大小不能超过 100MB');
      }
    },
    exportDemo() {
      if (!this.isEmptyData(this.items)&&!this.isEmptyData(this.items.demoFile)) {
        let d=this.items.demoFile;
        if(!this.isEmptyData(d.fullName)){
          let type = d.fullName.substring(d.fullName.lastIndexOf('.')+ 1)
          this.downloadFiles({name: d.fullName, url: this.imgUrl + "/" + this.companyId + "/" + d.fileName,type: type})
        }
      }
    },
    downloadFiles(file){
      var link = document.createElement('a');
      link.setAttribute('download', "");
      link.setAttribute('target', "_blank");
      link.setAttribute('href', file.url);
      link.click();
      //document.body.removeChild(link)
    },
  },
}
</script>

<style scoped>

</style>