<template>
  <div>
    <van-row>
      <van-col span="24" style="text-align: center;margin-top: 10vh;">
        <van-icon name="checked" color="#1989fa" size="60px"/>
      </van-col>
    </van-row>
    <p class="font14 mc mt">提交成功！</p>
  </div>
</template>

<script>
export default {
  name: "success",
}
</script>

<style scoped>
.font14{font-size: 14px;}
.col{color: #1989f0;}
.mc{text-align: center;}
.mt{margin-top: 20px;}
</style>