<template>
  <div>
    <van-field v-if="calc == 'add'" v-model="ruleForm[item.name]" readonly
               :rules="item.required?[{required:true,message:'必填'}]:[{}]"></van-field>
    <van-field v-else-if="calc == 'edit'" v-model="ruleForm[item.name]" :label="item.title" readonly label-width="120"
               label-align="right" :rules="item.required?[{required:true,message:'必填'}]:[{}]"></van-field>
    <span>{{calcData}}</span>
  </div>
</template>

<script>
import mixins from "@/mixins/index"
export default {
  name: "calcElement",
  mixins: [mixins],
  props: ["ruleForm","item","name","tableData","tlist","calc"],
  computed:{
    calcData(){
      this.calcMode();
      //console.log("calc",this.calc);
    },
  },
  mounted() {
    this.calcMode();
    //console.log("ruleform",this.ruleForm['field308'],this.calc);
    //console.log("tlist",this.tlist)
  },
  methods: {
    calcMode(){
      if (!this.isEmptyData(this.item.source) && !this.isEmptyData(this.item.source.formula)) {
        let formula = JSON.parse(JSON.stringify(this.item.source.formula));
        let array = this.item.source.arrays;
        let list = array.filter(x => x.type == "field");
        /*list= list.sort(this.comparData("name", "descending"));*/

        if (list != undefined && list != null && list.length > 0) {

          //获取计算控件所在位置
          let gdata = this.getGroup(this.item.name);

          for (let i in list) {
            let t = list[i];
            //判断计算控件所在位置
            if (gdata.type == "list"||gdata.type=="template") {
              if (formula.indexOf("this.ruleForm['" + t.name + "']") === -1) {
                //判断字段是否存在，不存在默认为0

                if(!this.isEmptyData(this.ruleForm[t.name])){
                  formula = formula.replace(new RegExp(t.name, "gm"), "this.ruleForm['" + t.name + "']");
                }else {
                  formula = formula.replace(new RegExp(t.name, "gm"), 0);
                }

              }
            } else {
              let gt = this.getGroup(t.name);
              if (gt.type == "list"||gt.type=="template") {
                let num = 0;
                //判断是否模板，如果模板反推列表叠加
                if(gt.type=="template"){
                  let glist = this.getGroupList(gt.name);
                  if(glist&&glist.length>0){
                    for(let tem in glist){
                      let ts = glist[tem];
                      let table = this.tableData[ts.name];
                      if (table) {
                        for (let i in table) {
                          let n = table[i];
                          let nval = 0;
                          if (!this.isEmptyData(n[t.name])) {
                            nval = n[t.name];
                          }
                          num += nval;
                        }
                      }
                    }
                  }
                }else{
                  let table = this.tableData[gt.name];
                  if (table) {
                    for (let i in table) {
                      let n = table[i];
                      let nval = 0;
                      if (!this.isEmptyData(n[t.name])) {
                        nval = n[t.name];
                      }
                      num += nval;
                    }
                  }
                }

                formula = formula.replace(new RegExp(t.name, "gm"), num);
              } else {
                if(formula.indexOf("this.ruleForm['" + t.name + "']")===-1) {
                  //判断字段是否存在，不存在默认为0
                  if(!this.isEmptyData(this.ruleForm[t.name])){
                    //parseFloat()
                    formula = formula.replace(new RegExp(t.name, "gm"), "this.ruleForm['" + t.name + "']");
                  }else{
                    formula = formula.replace(new RegExp(t.name, "gm"), 0);
                  }
                  //formula = formula.replace(new RegExp(t.name, "gm"), "this.ruleForm['" + t.name + "']");
                }
              }

            }
          }
        }
        let val = 0;
        val = eval(formula)
        if (isNaN(val)) {
          val = 0;
        }
          //if (formula.indexOf("*0") === -1){}
        //console.log("formula",formula,val);

        /*if(this.item.name=="field184"){
          console.log("formula",formula,val,this.item.source.formula,this.item.name)
        }*/
        this.$set(this.ruleForm, this.item.name, (Math.round(val * 100) / 100));
        //console.log("11111",this.ruleForm[this.item.name]);
        //this.ruleForm[this.item.name] = val;
      }
      //return this.ruleForm[this.item.name];
    },
    getGroup(name){
      let gdata={};
      if(!this.isEmptyData(this.tlist)&&!this.isEmptyData(this.tlist.groups)){
        for(let i in this.tlist.groups){
          let group=this.tlist.groups[i];
          if(group.fields||group.hidefields) {
            let field = group.fields.find(x => x.name == name);
            if(field){
              gdata=group;
              break;
            }
          }
        }
      }
      return gdata;
    },
    getGroupList(tempName){
      if(!this.isEmptyData(this.tlist)&&!this.isEmptyData(this.tlist.groups)){
        return this.tlist.groups.filter(x=>x.tempName==tempName);
      }
      return null;
    },
    getVal(val){
      this.$emit('getForm',this.ruleForm);
    },
  }
}
</script>

<style scoped>
/deep/.van-field__control:disabled{
  color: #333333;
}
</style>