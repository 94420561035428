<template>
  <div>
    <div v-for="(its,ke) in listinfo" :key="ke" class="mt10 font13">
      <div v-if="its.name == items.name">
        <div v-for="(it,k) in its.data" :key="k">
          <!--                    <div>{{ite.grouptype}}</div>-->
          <div class="mright mt10">
            <span class="mycursor paddzy20" @click="deleteItem(its,k)"><van-icon name="clear" color="red" size="18"/></span>
          </div>
          <div v-for="ite in items.fields" :key="ite.name">
            <div v-if="ite.visible == true">
              <div v-if="ite.grouptype == 'select'">
                <div v-if="ite.type == 'select'">
                  <select-element :key="k+ke+ite.name+'edits'" :items="ite" :ruleForm="it" :name="'edits'"></select-element>
<!--                  <div v-if="ite.source.data.length > 0 || ite.type == 'dic'">
                    <van-field :rules="ite.required?[{required:true,message:'必填'}]:[{}]" v-model="it[ite.name].label" :label="ite.title" placeholder="" readonly label-width="120" label-align="right"/>
                  </div>
                  <div v-else>
                    <van-field v-if="!isEmptyData(it[ite.name])" :rules="ite.required?[{required:true,message:'必填'}]:[{}]" v-model="it[ite.name].label" :label="ite.title" placeholder="" readonly label-width="120" label-align="right"/>
                    <van-field v-else :rules="ite.required?[{required:true,message:'必填'}]:[{}]" :label="ite.title" placeholder="" readonly label-width="120" label-align="right"/>
                  </div>-->
                </div>
                <div v-else-if="ite.type == 'mutiselect'">
                  <mutiselect-element :items="ite" :ruleForm="it" :name="'edits'"></mutiselect-element>
<!--                  <van-field :label="ite.title" readonly label-width="120" label-align="right" :rules="ite.required?[{required:true,message:'必填'}]:[{}]">
                    <template #input>
                      <div v-if="!isEmptyData(it[ite.name])">
                        <span v-for="(muti,k) in it[ite.name]" :key="k" >
                          <span v-if="ite.source.data.length > 0">{{ muti }}，</span>
                          <span v-else>{{ muti.label }}，</span>
                        </span>
                      </div>
                      <div v-else class=""></div>
                    </template>
                  </van-field>-->
                </div>
              </div>
              <div v-else-if="ite.grouptype == 'number'">
                <div v-if="ite.type == 'number'">
                  <van-field v-model.number="it[ite.name]" :label="ite.title" type="digit" placeholder="整数" @input="getItem($event,ite,it,ke,k)"
                             :disabled="ite.editdis" label-width="120" label-align="right"/>
                </div>
                <div v-else-if="ite.type == 'decimal'">
                  <van-field v-model.number="it[ite.name]" :label="ite.title" type="number" placeholder="可小数" @input="getItem($event,ite,it,ke,k)"
                             :disabled="ite.editdis" label-width="120" label-align="right"/>
                </div>
              </div>
              <div v-else-if="ite.grouptype == 'date'">
                <div v-if="ite.type=='date'">
                  <date-element :items="ite" :ruleForm="ruleForm" :name="'edit'"></date-element>
                </div>
                <div v-else-if="ite.type=='time'">
                  <time-element :items="ite" :ruleForm="ruleForm" :name="'edit'"></time-element>
                </div>
                <div v-else-if="ite.type=='datetime'">
                  <datetime-element :items="ite" :ruleForm="ruleForm" :name="'edit'"></datetime-element>
                </div>
              </div>
              <div v-else-if="ite.grouptype == 'calc'">
                <div v-if="ite.type == 'calc'">
<!--                  <calc-element :key="'calc'+ite.name" :keys="k" :item="ite" :ruleForm="ruleForm" :tableData="tableData" :tlist="tlist" :calc="'edit'"></calc-element>-->
                  <van-field v-model.number="it[ite.name]" :label="ite.title" label-width="120" label-align="right" readonly/>
                </div>
              </div>
              <div v-else-if="ite.grouptype == 'img'">
                <img-element :items="ite" :ruleForm="ruleForm" :file="it[ite.name]" v-bind="$attrs" :keys="k" :dis="'edit'" @getForms="getForms"></img-element>
              </div>
              <div v-else-if="ite.grouptype == 'file'">
                <img-element :items="ite" :ruleForm="ruleForm" :file="it[ite.name]" v-bind="$attrs" :keys="k" :dis="'edit'" @getForms="getForms"></img-element>
              </div>
              <div v-else>
                <div v-if="ite.type == 'phone'">
                  <van-field v-model="it[ite.name]" type="tel" :label="ite.title"  label-width="120" label-align="right" :readonly="ite.editdis"/>
                </div>
                <div v-else>
                  <van-field v-model="it[ite.name]" :label="ite.title"  label-width="120" label-align="right" :readonly="ite.editdis"/>
                </div>
              </div>
            </div>
          </div>
<!--          <div class="padd10"></div>-->
          <div class="mtb3" v-if="k !== (its.data.length - 1)"><div class="padd3 bgf2"></div></div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import mixins from "@/mixins/index"
import calcElement from "@/views/components/details/calcElement";
import dateElement from "@/views/components/details/dateElement";
import datetimeElement from "@/views/components/details/datetimeElement";
import timeElement from "@/views/components/details/timeElement";
import imgElement from "@/views/components/details/imgElement";
import selectElement from "@/views/components/details/selectElement";
import mutiselectElement from "@/views/components/details/mutiselectElement";
export default {
  name: "editList",
  mixins: [mixins],
  props:['listinfo','items','ruleForm',"tableData","tlist"],
  components: {calcElement,dateElement,datetimeElement,timeElement,imgElement,selectElement,mutiselectElement},
  data(){
    return{
      calcitem: {filed: []},
      item: {},
      itemform: {},
      currentDate: "",
      currentTime: "",
      currentDateTime: "",
    }
  },
  computed:{
    /*calcData() {
      this.calcMode();
      console.log("it-change")
    },*/
  },
  mounted() {
    //console.log("eitruleform",this.listinfo);
    if (!this.isEmptyData(this.items)){
      for (let i in this.items.fields){
        let it = this.items.fields[i];
        if (it.visible){
          if (it.type == 'calc'){
            if (!this.isEmptyData(it.source.arrays) && it.source.arrays.length > 0){
              for (let j in it.source.arrays){
                let  its = it.source.arrays[j];
                if (its.type == "field"){
                  this.calcitem[its.name] = [];
                  this.calcitem[its.name].push(it);
                }
              }
            }
          }
        }
      }
    }
    //this.calcMode();
  },
  methods: {
    getForms(data,name,k){
      this.$emit('getLists',data,name,k,this.items);
    },
    deleteItem(it,ke){
      for (let i in this.listinfo){
        let info = this.listinfo[i];
        if (info.name == it.name){
          info.data.splice(ke,1);
        }
      }
      /*this.listinfo.splice(ke,1);
      this.listinfos.splice(ke,1);*/
      let data1 = this.listinfo;
      let data = JSON.stringify(data1);
      localStorage.removeItem('listinfo');
      localStorage.setItem('listinfo',data);
    },
    getItem(e,it,it1,ke,k){
      this.itemform = it1;
      //console.log("typeof",it1[it.name],typeof it1[it.name])
      if (!this.isEmptyData(this.calcitem[it.name])){
        for (let i in this.calcitem[it.name]){
          let its = this.calcitem[it.name][i];
          this.item = its;
          this.calcMode(ke,k);
        }
      }
    },
    calcMode(ke,k){
      if (!this.isEmptyData(this.item.source) && !this.isEmptyData(this.item.source.formula)) {
        let formula = JSON.parse(JSON.stringify(this.item.source.formula));
        let array = this.item.source.arrays;
        let list = array.filter(x => x.type == "field");
        /*list= list.sort(this.comparData("name", "descending"));*/
        if (list != undefined && list != null && list.length > 0) {

          //获取计算控件所在位置
          let gdata = this.getGroup(this.item.name);
          for (let i in list) {
            let t = list[i];
            //判断计算控件所在位置
            if (gdata.type == "list"||gdata.type=="template") {
              if (formula.indexOf("this.itemform['" + t.name + "']") === -1) {
                //判断字段是否存在，不存在默认为0

                if(!this.isEmptyData(this.itemform[t.name])){

                  formula = formula.replace(new RegExp(t.name, "gm"), "this.itemform['" + t.name + "']");

                }else {
                  formula = formula.replace(new RegExp(t.name, "gm"), 0);
                }

              }
            } else {
              let gt = this.getGroup(t.name);
              if (gt.type == "list"||gt.type=="template") {
                let num = 0;
                //判断是否模板，如果模板反推列表叠加
                if(gt.type=="template"){
                  let glist = this.getGroupList(gt.name);
                  if(glist&&glist.length>0){
                    for(let tem in glist){
                      let ts = glist[tem];
                      let table = this.tableData[ts.name];
                      if (table) {
                        for (let i in table) {
                          let n = table[i];
                          let nval = 0;
                          if (!this.isEmptyData(n[t.name])) {
                            nval = n[t.name];
                          }
                          num += nval;
                        }
                      }
                    }
                  }
                }else{
                  let table = this.tableData[gt.name];
                  if (table) {
                    for (let i in table) {
                      let n = table[i];
                      let nval = 0;
                      if (!this.isEmptyData(n[t.name])) {
                        nval = n[t.name];
                      }
                      num += nval;
                    }
                  }
                }

                formula = formula.replace(new RegExp(t.name, "gm"), num);
              } else {
                if(formula.indexOf("this.itemform['" + t.name + "']")===-1) {
                  //判断字段是否存在，不存在默认为0
                  if(!this.isEmptyData(this.itemform[t.name])){
                    //parseFloat()
                    formula = formula.replace(new RegExp(t.name, "gm"), "this.itemform['" + t.name + "']");
                  }else{
                    formula = formula.replace(new RegExp(t.name, "gm"), 0);
                  }
                  //formula = formula.replace(new RegExp(t.name, "gm"), "this.ruleForm['" + t.name + "']");
                }
              }

            }
          }
        }
        let val = 0
        val = eval(formula)
        if (isNaN(val)) {
          val = 0;
        }
          //if (formula.indexOf('*0') === -1){}

        /* console.log("formula",formula,val);*/

        /*if(this.item.name=="field184"){
          console.log("formula",formula,val,this.item.source.formula,this.item.name)
        }*/
        this.$set(this.listinfo[ke].data[k], this.item.name, (Math.round(val * 100) / 100));
        //console.log("calc",this.listinfo[ke].data[k][this.item.name],typeof this.listinfo[ke].data[k][this.item.name])
        //this.ruleForm[this.item.name] = val;
      }
      //return this.ruleForm[this.item.name];
    },
    getGroup(name){
      let gdata={};
      if(!this.isEmptyData(this.tlist)&&!this.isEmptyData(this.tlist.groups)){
        for(let i in this.tlist.groups){
          let group=this.tlist.groups[i];
          if(group.fields||group.hidefields) {
            let field = group.fields.find(x => x.name == name);
            if(field){
              gdata=group;
              break;
            }
          }
        }
      }
      return gdata;
    },
    getGroupList(tempName){
      if(!this.isEmptyData(this.tlist)&&!this.isEmptyData(this.tlist.groups)){
        return this.tlist.groups.filter(x=>x.tempName==tempName);
      }
      return null;
    },

  }
}
</script>

<style scoped>
/deep/ .van-cell{padding: 3px 12px;}
/*/deep/ .van-field--disabled .van-field__label {
  color: #666;
}
/deep/ .van-field--disabled .van-field__input{
  color: #7F7F7F;
}*/
</style>